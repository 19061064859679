import * as React from 'react';

import { ImageLoadingBehaviorOptions } from 'wix-ui-tpa';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { ImageResizeMode } from '@wix/search-settings-client';

import { CroppedThumbnail } from './CroppedThumbnail';
import { FittedThumbnail } from './FittedThumbnail';
import { IThumbnailProps } from '../Thumbnail';

export type IResizedThumbnailProps = Pick<
  IThumbnailProps,
  | 'height'
  | 'image'
  | 'maxResponsiveImageHeight'
  | 'maxResponsiveImageWidth'
  | 'width'
> & {
  alt?: string;
  aspectRatio?: number;
  fluid: boolean;
  height: number;
  loadingBehavior: ImageLoadingBehaviorOptions;
  src: string;
  resizeMode: ImageResizeMode;
};

export const ResizedThumbnail: React.FC<IResizedThumbnailProps> = ({
  image,
  maxResponsiveImageWidth,
  maxResponsiveImageHeight,
  height,
  width,
  resizeMode,
  ...rest
}) => {
  const { isEditorX } = useEnvironment();

  const commonProps = {
    ...rest,
    height: isEditorX ? maxResponsiveImageHeight : height,
    width: isEditorX ? maxResponsiveImageWidth : width,
  };

  return (
    <div
      style={{
        height: isEditorX ? '100%' : height,
        width: isEditorX ? '100%' : width,
      }}
    >
      {resizeMode === ImageResizeMode.Fit ? (
        <FittedThumbnail {...commonProps} image={image} />
      ) : (
        <CroppedThumbnail {...commonProps} />
      )}
    </div>
  );
};
