import * as React from 'react';

import { useEnvironment } from '@wix/yoshi-flow-editor';
import { ImageLoadingBehaviorOptions } from 'wix-ui-tpa';
import { IDocumentImage } from '@wix/client-search-sdk';
import { ImageResizeMode } from '@wix/search-settings-client';

import { stripMarkTags } from '../stripMarkTags';
import { getImageSrc } from './getImageSrc';
import { ResizedThumbnail } from './ResizedThumbnail';

import { classes } from './Thumbnail.st.css';

export type IThumbnailProps = {
  height: number;
  image: NonNullable<IDocumentImage>;
  maxResponsiveImageHeight: number;
  maxResponsiveImageWidth: number;
  onClick?: React.MouseEventHandler<HTMLElement>;
  resizeMode: ImageResizeMode;
  title: string;
  url?: string;
  width: number;
};

export const Thumbnail: React.FC<IThumbnailProps> = ({
  height,
  image,
  maxResponsiveImageHeight,
  maxResponsiveImageWidth,
  onClick,
  resizeMode,
  title,
  url,
  width,
}) => {
  const { isEditorX } = useEnvironment();

  const commonProps = {
    'aria-hidden': true,
    'data-hook': 'item-thumbnail',
    onClick,
    tabIndex: -1,
    title: stripMarkTags(title),
  };

  const resizedThumbnail = (
    <ResizedThumbnail
      alt={commonProps.title}
      aspectRatio={isEditorX ? width / height : undefined}
      fluid={isEditorX}
      height={height}
      image={image}
      loadingBehavior={ImageLoadingBehaviorOptions.blur}
      maxResponsiveImageHeight={maxResponsiveImageHeight}
      maxResponsiveImageWidth={maxResponsiveImageWidth}
      src={getImageSrc(image)}
      width={width}
      resizeMode={resizeMode}
    />
  );

  const thumbnail = url ? (
    <a {...commonProps} href={url}>
      {resizedThumbnail}
    </a>
  ) : (
    <span {...commonProps}>{resizedThumbnail}</span>
  );

  return isEditorX ? (
    // This part is needed for SSR in responsive. `Image` component in fluid mode renders in
    // SSR with `height: 0` and which causes flicker. To solve that issue added bounding
    // container with height and width based on aspect ratio. This is workaround. I think it
    // should be fixed on TPA side.
    <div
      className={classes.thumbnailAspectRatio}
      style={{
        paddingTop: `${(height / width) * 100}%`,
      }}
    >
      <div className={classes.thumbnailContent}>{thumbnail}</div>
    </div>
  ) : (
    thumbnail
  );
};
